import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer } from '../components/Blocks'
import { HeroVideo, CoreValues } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

const Block = styled.div`
  height: 55vh;
  @media screen and (min-width: 1800px) {
    height: 80vh;
  }
  @media screen and (min-width: 2600px) {
    height: 100vh;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="About SIDC" keywords={[`gatsby`, `application`, `react`]} />
    <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/anniversary.mp4" posterSrc={getAssetUrl('app/video-poster.png')} loop={true} autoplay muted>
    {/* <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/"  posterSrc={getAssetUrl('app/ASEAN_Banner.jpg')} loop={true} autoplay muted> */}
      <Block />
    </HeroVideo>
    <BlockContainer padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Sorosoro Ibaba Development Cooperative (SIDC)</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={3} center={false}>
      <Container>
        <Row>
          <Col md={{ size: 6, order: 1 }} xs={{ size: 12, order: 1}}>
            <Row className="text-center text-md-left">
              <Col md="6">
                <img className="img-fluid d-block m-auto" src={getAssetUrl('app/Victoriano E Barte.png.jpeg')} alt="CHAIRPERSON" />
              </Col>
              <Col md="6" sm="12">
                <p className="lead"><b>Founder</b></p>
                <p className="lead"><b>Victoriano E. Barte</b></p>
              </Col>
            </Row>
          </Col>
          <Col md={{ size: 6, order: 2 }} xs={{ size: 12, order: 3}}>
            <Row className="text-center text-md-left">
              <Col md="6">
                <img className="img-fluid d-block m-auto" src={getAssetUrl('app/Incorporators.png.jpeg')} alt="INCORPORATORS" />
              </Col>
              <Col md="6">
                <p className="lead"><b>Incorporators</b></p>
              </Col>
            </Row>
          </Col>
          <Col md={{ size: 6, order: 3 }} xs={{ size: 12, order: 2}} className="my-3">
            <div className="mb-3 text-justify">
              <p>Sorosoro Ibaba Development Cooperative (SIDC) is first established as Sorosoro Ibaba Farmers' Association last March 19, 1969, thru Mr. Victoriano E. Barte who acted as the President and as the General Manager. It was registered with the Securities and Exchange Commission and was issued with a license. The association was funded initially by 59 members who voluntarily contributed two hundred pesos each for a total capital of P11,800.00. It was used for the construction of a goods store that offered basic commodities needed by the residents. Since some have started poultry and hog raising, feeds and other veterinary products were made available. The association has also become a dealer of different products, which were offered at low prices.</p>
            </div>
          </Col>
          <Col md={{ size: 6, order: 4 }} xs={{ size: 12, order: 4}} className="border-md-left my-3">
            <div className="mb-3 text-justify">
              <p>In 1972, the association became the Samahang Nayon ng Sorosoro Ibaba. Contract-growing activities were started. The success of the operation has strongly encouraged additional members to a total of 500, large enough for an organization to qualify as a full-pledged cooperative. Samahang Nayon ng Sorosoro Ibaba, Inc. became Sorosoro Ibaba Consumers' Cooperative in November, 1978. Five years later, it was registered with the Ministry of Agriculture as a development cooperative. Only then the cooperative was renamed Sorosoro Ibaba Development Cooperative, Inc. (SIDCI). It became Sorosoro Ibaba Development Cooperative (SIDC) in 1997.</p>
            </div>
          </Col>
          <Col xs={{ size: 12, order: 5 }}>
            <p>As of May 2024, the cooperative has 49,033 regular members and 22,912 associate members. It is foreseen to increase due to the successes and the benefits it has been providing not only to members but to the community as well. The membership came from Batangas province and nearby provinces like Laguna, Quezon, Oriental Mindoro, Occidental Mindoro, Romblon, Metro Manila, Bataan, Zambales, Tarlac, Nueva Ecija, Bulacan, Bicol, Aklan and Antique. It now employs 335 regular employees, most of them are members of the coop.</p>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <CoreValues />
    {/* <BlockContainer padding={5} dark id="awards">
      <Container>
          <Row>
          <Col md="12">
              <div className="text-center">
                <h2 className="pb-4 text-dark">Awards</h2>
              </div>
          </Col>
          </Row>
          <Row>
          <Col sm={{order: 2}} md={{ size: 3, order: 1}} className="align-self-center text-md-right text-center">
              <h4 className="text-primary">Platinum Award Gawad Pitak 2013 Land Bank of the Philippines</h4>
              <p className="mb-5">(August, 2013)</p>
              <h4 className="text-primary">Platinum Award Gawad Pitak 2013 Land Bank of the Philippines</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          <Col sm={{ order: 1 }} md={{ size: 6, order: 2}} className="my-3 d-none d-md-block">
              <img className="img-fluid d-block pt-5" alt="SIDC BENEFITS" src={getAssetUrl('app/Awards.png')} />
          </Col>
          <Col sm={{order: 3}} md={{ size: 3, order: 3}} className="align-self-center text-md-left text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          </Row>
      </Container>
    </BlockContainer> */}
    {/* <Parallax src="app/parallax.jpg">
      <Row>
        <Col sm="6" className="text-sm-left">
          <h1 className="text-primary">
              <b>Read about <br /> SIDC Culture</b>
          </h1>
        </Col>
        <Col sm="6" className="text-sm-right d-flex align-items-end flex-column">
          <Link to="/culture" className="btn btn-primary mt-auto mb-auto"><i className="fa fa-download fa-fw"></i> SIDC Culture</Link>
        </Col>
      </Row>
    </Parallax> */}
  </Layout>
)

export default IndexPage
